import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import numbro from "numbro";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { showDateSelect } from "../../redux/actions/date-select-actions";
import { CONSULT_STATUS, hasBookingInvoices } from "../../redux/reducers";
import CancelDialog from "../../containers/consult-cancel-dialog";
import EditIcon from "../../icons/edit-icon";
import AlertIcon from "../../icons/alert-icon";
// theme
import theme from "../../theme";
import ActionButtons from "./actions-buttons";

import { getDays, formatDate, isBefore } from "../../utils/dateUtils";

// config
import config from "../../config";
import { priceFromPaymentPlan } from "../../utils";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 295,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  dates: {
    textTransform: "uppercase",
    fontWeight: "bold",
    paddingLeft: 0,
  },
  changeDates: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  infoText: {
    textTransform: "uppercase",
  },
  uppercase: { textTransform: "uppercase" },
  divider: {
    backgroundColor: theme.palette.black,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cancelRequest: {
    textDecoration: "underline",
    cursor: "pointer",
    textTransform: "none",
    textAlign: "right",
    width: "100%",
    maxWidth: 295,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  seeAdSpaces: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  fromPrice: {
    display: "flex",
  },
  infoBox: {
    display: "flex",
    flexDirection: "row",
    height: "60px",
    width: "100%",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(5 / 2),
    backgroundColor: theme.palette.lightYellow,
    alignItems: "center",
  },
}));

function SpaceInfoPaper({
  space = { asset: { city: {} } },
  booking,
  AdSpaceModal = () => <></>,
  goToPayment,
  showOnlyInfo = false,
  invoice = {},
  onSeeTenantBookingInvoices,
  fromPrice,
  isLoadingBooking,
  hideCancelRequestButton,
}) {
  const dispatch = useDispatch();
  const classes = useStyles(theme);
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { i18n } = useLingui();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openModalAdSpaces, setOpenModalAdSpaces] = useState(false);

  const { title, image, id } = space;

  const {
    startDate,
    endDate,
    isLongTerm,
    state,
    asset,
    price = 0,
    periodPrice = 0,
    paymentPlan,
    bookingId,
    adSpaces,
  } = booking;

  let totalDays;
  if (invoice.duration) {
    totalDays = invoice.duration;
  } else {
    totalDays = getDays(startDate, endDate);
  }

  const imageHeight = smallScreen ? 80 : 110;

  const canOpenModalAdSpace = isBefore(Date.now(), startDate);

  const canModifyBooking = [CONSULT_STATUS.PENDING, CONSULT_STATUS.REQUESTED, CONSULT_STATUS.ACCEPTED].includes(state);

  const handleSpaceImage = () => {
    if (isLoadingBooking) {
      return <Skeleton animation="wave" variant="rect" width="100%" height={imageHeight} />;
    }
    const img = id === undefined || id === "loading" ? config.ADVERTISING_IMG : image;

    return (
      <Image
        src={img}
        imageStyle={{ height: imageHeight, objectFit: "cover" }}
        style={{ height: imageHeight * 1.1, paddingTop: 0 }}
      />
    );
  };

  const canSeeInvoices = hasBookingInvoices(booking.state) && onSeeTenantBookingInvoices;

  const handleOpenChangeDates = () => {
    if (canModifyBooking) {
      dispatch(
        showDateSelect({
          spaceId: space.id,
          bookingId,
          startDate,
          endDate,
        }),
      );
    }
  };

  const handleAdSpaceTooltip = () => {
    if (adSpaces?.length > 0) {
      return (
        <>
          <span style={{ textDecoration: "underline" }}>{i18n._(t`Espacios publicitarios`)}</span>
          {adSpaces.map((ad) => (
            <p key={ad.id}>{ad.title}</p>
          ))}
        </>
      );
    }
    return <p>{i18n._(t`No hay espacio publicitario`)}</p>;
  };

  const getFormattedEndDate = () => {
    return formatDate(endDate, "DD/MM/YYYY", false);
  };

  return (
    <>
      <Paper className={classes.sidebar} elevation={5} square>
        <Box p={2}>
          <Box display="flex" flexDirection={["row", "column"]}>
            <Box flex={[1]}>
              <a href="#" target="_blank" rel="noopener noreferrer">
                {handleSpaceImage()}
              </a>
            </Box>
            {isLoadingBooking ? (
              <Box mt={1}>
                <Skeleton animation="wave" variant="rect" width="100%" height={50} />
              </Box>
            ) : (
              <Box ml={smallScreen ? 1 : 0} flex={[2]}>
                <Typography variant="h5" style={{ fontWeight: "bold" }} mb={1}>
                  {bookingId ? `#${bookingId} ` : ""} {title || ""}
                </Typography>
                <Box mt={1 / 2}>
                  <Typography variant="body1">
                    {asset?.name} - {asset?.city?.name}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" mt={1} mb={1}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="baseline"
                    onClick={handleOpenChangeDates}
                    style={{
                      cursor: bookingId && canModifyBooking ? "pointer" : "default",
                    }}
                  >
                    <Typography variant="h6" className={classes.dates}>
                      {formatDate(startDate, "DD/MM/YYYY", false)}
                      <span> - </span>
                      {isLongTerm ? <Trans>Estadía prolongada</Trans> : getFormattedEndDate()}
                    </Typography>
                    {bookingId && canModifyBooking && (
                      <Box ml={1 / 2}>
                        <EditIcon
                          style={{
                            width: "14px",
                            height: "14px",
                            transform: "translateY(1px)",
                          }}
                          fill={theme.palette.black}
                        />
                      </Box>
                    )}
                  </Box>
                  {!!booking.adSpaces?.length && (
                    <Tooltip arrow title={handleAdSpaceTooltip()}>
                      <Box
                        display="flex"
                        alignItems="baseline"
                        mt={1}
                        style={{
                          cursor: canOpenModalAdSpace ? "pointer" : "default",
                        }}
                        onClick={() => (canOpenModalAdSpace ? setOpenModalAdSpaces(true) : () => {})}
                      >
                        {!!booking.adSpaces?.length && (
                          <Typography variant="h6" className={classes.seeAdSpaces}>
                            {!!booking.adSpaces.length} <Trans>Espacios publicitarios seleccionados </Trans>
                          </Typography>
                        )}
                        {bookingId && canModifyBooking && canOpenModalAdSpace && (
                          <Box ml={1 / 2}>
                            <EditIcon
                              style={{
                                width: "14px",
                                height: "14px",
                                transform: "translateY(1px)",
                              }}
                              fill={theme.palette.black}
                            />
                          </Box>
                        )}
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Divider className={classes.divider} />
          {!isLoadingBooking && (
            <Typography variant="h5">
              <Grid justifyContent="space-between" container>
                {!isLongTerm && (
                  <Grid item>
                    {totalDays} <Trans>días</Trans>
                  </Grid>
                )}
                {bookingId !== undefined && bookingId > 0 && booking.showPricesInfo && (
                  <Grid>
                    {canSeeInvoices ? (
                      <Box>
                        <Typography variant="h5">{numbro(parseFloat(invoice.total)).formatCurrency()}</Typography>
                        {paymentPlan === "Monthly" && (
                          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
                            <Typography variant="body1" style={{ color: theme.palette.grayC4 }}>
                              (<Trans>Total</Trans>: {price ? numbro(parseFloat(price)).formatCurrency() : ""})
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box display="flex" flexDirection="column" alignItems="flex-end">
                        <Typography variant="body1" style={{ color: theme.palette.grayC4 }}>
                          (<Trans>Total</Trans>: {price ? numbro(parseFloat(price)).formatCurrency() : ""})
                        </Typography>
                        <Typography variant="h5">{priceFromPaymentPlan(paymentPlan, price, periodPrice)}</Typography>
                      </Box>
                    )}
                  </Grid>
                )}
                {fromPrice && (
                  <Grid className={classes.fromPrice}>
                    <Typography variant="h5">{numbro(fromPrice).formatCurrency()}</Typography>
                  </Grid>
                )}
              </Grid>
            </Typography>
          )}
          {bookingId > 0 && !showOnlyInfo && !isLoadingBooking && (
            <ActionButtons
              space={space}
              booking={booking}
              status={state}
              goToPayment={goToPayment}
              onSeeTenantBookingInvoices={onSeeTenantBookingInvoices}
            />
          )}
        </Box>
      </Paper>
      {!!openModalAdSpaces && <AdSpaceModal open={openModalAdSpaces} onClose={() => setOpenModalAdSpaces(false)} />}
      {bookingId > 0 &&
        !showOnlyInfo &&
        booking.state !== CONSULT_STATUS.CANCELED &&
        booking.state !== CONSULT_STATUS.PAID &&
        booking.state !== CONSULT_STATUS.DONE &&
        !hideCancelRequestButton && (
          <Box className={classes.cancelRequest}>
            <Typography variant="caption" onClick={() => setOpenCancelDialog(true)}>
              <Trans>Cancelar solicitud</Trans>
            </Typography>
            <CancelDialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)} />
          </Box>
        )}
    </>
  );
}

export default SpaceInfoPaper;
